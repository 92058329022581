import React, { useState } from 'react'
import { StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import * as queryString from 'querystring'
import Layout from '../components/Layout/Layout'
import { useLoad } from '../hooks/request'
import { PRODUCT_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import ProductCreate from '../components/ProductCreate'
import { useQueryParams } from '../hooks/queryString'
import Table from '../components/common/Table'
import CheckCreate from '../components/CheckCreate'
import ProductHomeItem from '../components/ProductHomeItem'
import ArchiveTabs from '../components/ArchiveTabs'

export default function ArchiveProducts() {
    const params = useQueryParams()
    const history = useHistory()
    const [page, setPage] = useState(1)
    const products = useLoad({
        url: PRODUCT_LIST,
        params: { page, ...params, status: 'archive' },
    }, [params, page])
    const productList = products.response ? products.response.results : []
    const check = useLoad({ url: '/main/order' })

    const [showCreateModal, setShowCreateModal] = useModal(
        <ProductCreate
            reload={products}
            onCancel={() => setShowCreateModal()}
        />,
        styles.modal,
    )
    const [showCreateCheck, hideCreateCheck] = useModal(
        <CheckCreate
            reload={check}
            onCancel={() => hideCreateCheck()}
        />,
    )

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({
            ...params,
            search,
        })}`)
    }

    return (
        <Layout onSearch={onSearch} showCreateModal={showCreateModal} showCreateCheck={showCreateCheck}>
            <ArchiveTabs />

            <div className="columns mt-2">
                <div className="column">
                    <div className="box" style={{ width: '100%' }}>
                        <div style={{
                            width: '100%',
                            overflowX: 'auto',
                        }}>
                            <Table
                                loading={products.loading}
                                items={productList}
                                columns={{
                                    photo: 'Фото',
                                    name: 'Название',
                                    category: 'Категории',
                                    count: 'Количество',
                                    priceSale: 'Цена продажи',
                                    priceBuy: 'Цена покупки',
                                    measurement: 'Измерение',
                                    actions: '',
                                }}
                                totalCount={products.response ? products.response.count : 0}
                                pageSize={15}
                                activePage={page}
                                onPageChange={setPage}
                                renderItem={(item) => (
                                    <ProductHomeItem
                                        products={products}
                                        check={check}
                                        key={item.id}
                                        item={item}

                                    />
                                )} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    emptyImg: {
        width: '330px',
        height:
            '300px',
    },
    loader: {
        marginTop: '2rem',
    },
    modal: {
        width: 1000,
    },
    checkmark: {
        marginRight: '3px',
    },

})
