import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as queryString from 'querystring'
import isEmpty from 'lodash/isEmpty'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { useLoad, usePostRequest } from '../hooks/request'
import Layout from '../components/Layout/Layout'
import { CLIENT_LIST, SEND_MESSAGE_SELECT, TOTAL_BORROW_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import { useQueryParams } from '../hooks/queryString'
import Table from '../components/common/Table'
import BorrowItem from '../components/BorrowItem'
import ClientCreate from '../components/ClientCreate'
import { useMessage } from '../hooks/message'
import BorrowTabs from '../components/BorrowTabs'
import { format } from '../utils/number'

export default function Borrow() {
    const params = useQueryParams()
    const history = useHistory()
    const [page, setPage] = useState(1)
    const clients = useLoad({
        url: CLIENT_LIST,
        params: {
            page,
            ...params,
            type: 'active',
        },
    }, [params, page])
    const productList = clients.response ? clients.response.results : []

    const [showCreateModal, setShowCreateModal] = useModal(
        <ClientCreate
            clients={clients}
            onCancel={() => setShowCreateModal()}
        />,
    )

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ ...params, search })}`)
    }
    const [checkedItems, setCheckedItems] = useState([])
    const handleCheckboxChange = (itemId, isChecked) => {
        setCheckedItems((prevState) => {
            if (isChecked) {
                return [...prevState, itemId]
            }
            return prevState.filter((id) => id !== itemId)
        })
    }
    const [showMessage] = useMessage()
    const send = usePostRequest({ url: SEND_MESSAGE_SELECT })

    async function onSend() {
        const { success } = await send.request({ data: { client_ids: checkedItems } })
        if (success) {
            showMessage('Сизнинг sms хабарингиз муваффақиятли юборилди', 'is-success')
            setCheckedItems([])
            clients.request()
        }
    }

    const total = useLoad({
        url: TOTAL_BORROW_LIST,
        params: { ...params },
    })

    return (
        <Layout onSearch={onSearch} onSend={onSend} showCreateModal={showCreateModal}>
            <BorrowTabs />
            <div>
                <div className="column">
                    <p className="is-capitalized mb-3">все долги:
                        <span
                            className="ml-2 has-text-danger">{format(total.response ? total.response.totalAmount : 0)} сум
                        </span>
                        <span className="ml-2 has-text-danger">{total.response ? total.response.date : ''}</span>
                    </p>
                </div>
                <div className={cn(css(styles.tableContainer), 'column is-12-mobile is-7-tablet')}>
                    <Table
                        loading={clients.loading}
                        items={productList}
                        columns={{
                            name: 'Имя',
                            address: 'Номер телефона',
                            price: 'Цена',
                            actisadons: 'Выберите',
                            actidssadons: 'Последней отправки',
                            action: '',
                        }}
                        totalCount={clients.response ? clients.response.count : 0}
                        pageSize={15}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item) => (
                            <BorrowItem
                                onSend={onSend}
                                onCheckboxChange={handleCheckboxChange}
                                clients={clients}
                                products={clients}
                                key={item.id}
                                item={item}
                            />
                        )} />
                </div>
            </div>

        </Layout>
    )
}

const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    tableContainer: {
        overflowX: 'auto', // Enables horizontal scrolling on small screens
        width: '100%',
    },
    table: {
        minWidth: '600px', // Ensures table content doesn't shrink too much
    },
    mobileText: {
        fontSize: '14px',
        '@media (max-width: 768px)': {
            fontSize: '12px', // Adjust text size for smaller screens
        },
    },
    actionButton: {
        padding: '8px 12px',
        fontSize: '14px',
        '@media (max-width: 480px)': {
            fontSize: '12px', // Smaller button text for mobile
            padding: '6px 10px',
        },
    },
})
