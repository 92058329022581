import React from 'react'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useQueryParams } from '../../hooks/queryString'

export default function DateFilter({
    isClearable = true,
    className = '',
    name = '',
    pageKey = 'page',
    ...props
}) {
    const history = useHistory()
    const params = useQueryParams()
    const val = params[name] || ''

    function onChange(e) {
        const { value } = e.target
        history.push(`?${queryString.stringify({
            ...params,
            [name]: value,
            [pageKey]: 1,
        })}`)
    }

    return (
        <div className={cn(css(styles.inputDiv), className)}>
            <input type="date" defaultValue={val} onChange={onChange}
                className={cn('input', isClearable && val && css(styles.input))} {...props} />
            {isClearable && val && (
                <i
                    className="icon"
                    onClick={() => {
                        history.push(`?${queryString.stringify({
                            ...params,
                            [name]: undefined,
                            [pageKey]: 1,
                        })}`)
                    }}
                >
                    <ion-icon name="close-outline" />
                </i>
            )}
        </div>
    )
}

const styles = StyleSheet.create({
    inputDiv: {
        width: 159,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        ':nth-child(1n) input': { display: 'block' },
        ':nth-child(1n) .icon': {
            position: 'absolute',
            right: 10,
            cursor: 'pointer',
        },
    },
    input: { paddingRight: '40px' },
})
