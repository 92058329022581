import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import queryString from 'querystring'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { useLoad } from '../hooks/request'
import { ORDER_ARCHIVE_LIST } from '../urls'
import Layout from '../components/Layout/Layout'
import empty from '../static/empty-product.png'
import Table from '../components/common/Table'
import { useQueryParams } from '../hooks/queryString'
import ArchiveItem from '../components/ArchiveItem'
import ArchiveTabs from '../components/ArchiveTabs'
import { format } from '../utils/number'
// import { format } from '../utils/number'

export default function Archive() {
    const params = useQueryParams()
    const [page, setPage] = useState(1)
    const ordersList = useLoad({
        url: ORDER_ARCHIVE_LIST,
        params: {
            page,
            ...params,
        },
    }, [params, page])
    const history = useHistory()
    const orders = ordersList.response ? ordersList.response.results : []
    const totalForOrderHistoriesSum = ordersList.response ? ordersList.response.totalForOrderHistoriesSum : 0
    const totalForOrderHistoriesSumBuy = ordersList.response ? ordersList.response.totalForOrderHistoriesSumBuy : 0
    const total = totalForOrderHistoriesSum - totalForOrderHistoriesSumBuy
    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({
            ...params,
            search,
        })}`)
    }

    return (
        <Layout onSearch={onSearch}>
            <ArchiveTabs />

            <div className="columns">
                <div className="column">
                    {orders.response && orders.response.count === 0 ? (

                        <div className="is-flex is-justify-content-center is-align-items-center hei">
                            <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                        </div>

                    ) : (
                        <div className="box" style={{ width: '100%' }}>
                            <div style={{
                                width: '100%',
                                overflowX: 'auto',
                            }}>
                                <Table
                                    loading={ordersList.loading}
                                    items={orders}
                                    columns={{
                                        name: 'Название',
                                        price: 'Номер телефона',
                                        ds: 'Итого',
                                        registered_at: 'Создан в ',
                                        actions: '',
                                    }}
                                    totalCount={ordersList.response ? ordersList.response.count : 0}
                                    pageSize={15}
                                    activePage={page}
                                    onPageChange={setPage}
                                    renderItem={(item) => (
                                        <ArchiveItem
                                            ordersList={ordersList}
                                            key={item.id}
                                            item={item}

                                        />
                                    )} />
                            </div>
                        </div>
                    )}
                    <div className="box is-flex">
                        <p className="mr-3">Общая сумма продаж: {format(totalForOrderHistoriesSum)}сум</p>
                        <p>Общая сумма выгоде: {format(total)}сум</p>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    emptyImg: {
        width: '330px',
        height:
                    '300px',
    },
    loader: {
        marginTop: '2rem',
    },
    modal: {
        height: 400,
    },
})
