import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import Button from './common/Button'
import { useModal } from '../hooks/modal'
import ViewCheckArchive from './ViewCheckArchive'
import { getDateTime } from '../utils/date'
import ComponentToPrint from './Print'
import { usePutRequest } from '../hooks/request'
import { ORDER_DETAIL } from '../urls'
import { format } from '../utils/number'

export default function ArchiveItem({
    item,
    ordersList,
}) {
    const [showViewModal, setShowViewModal] = useModal(
        <ViewCheckArchive
            item={item}
            hideModal={() => setShowViewModal()}
        />,
    )
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })
    const updateOrder = usePutRequest({ url: ORDER_DETAIL.replace('{id}', item.id) })

    async function onSubmit(data) {
        const { success } = await updateOrder.request({
            data: {
                ...data,
                status: 'active',
                items: item.orderProduct,
                client: item.client.id,
            },
        })
        if (success) {
            ordersList.request()
        }
    }

    return (
        <tr key={item.id}>
            <td onClick={showViewModal} className="is-size-5">{item.client.name}</td>
            <td className="is-size-5">{item.client.phoneNumber}</td>
            <td className="is-size-5">{format(item.amount)}</td>
            <td className="is-size-5">{getDateTime(item.createdAt)}</td>
            <td className="has-text-right">
                <div className="is-pulled-right is-flex">
                    <Button
                        onClick={handlePrint}
                        className="button is-link mr-2"
                        icon="print" />
                    <div style={{ display: 'none' }}>
                        <ComponentToPrint result={item.totalForOrderHistories} item={item} ref={componentRef} />
                    </div>
                    <Button
                        onClick={() => onSubmit()}
                        className="button is-success"
                        icon="trash" />
                </div>
            </td>
        </tr>
    )
}
