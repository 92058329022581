import React, { useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import * as queryString from 'querystring'
import cn from 'classnames'
import Layout from '../components/Layout/Layout'
import { useLoad } from '../hooks/request'
import { PRODUCT_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import ProductCreate from '../components/ProductCreate'
import { useQueryParams } from '../hooks/queryString'
import empty from '../static/empty-shop.png'
import Table from '../components/common/Table'
import ProductItem from '../components/ProductItem'
import CheckCreate from '../components/CheckCreate'
import CheckItem from '../components/CheckItem'
import Loader from '../components/common/Loader'

export default function Products() {
    const params = useQueryParams()
    const history = useHistory()
    const [page, setPage] = useState(1)
    const products = useLoad({
        url: PRODUCT_LIST,
        params: { page, ...params, status: 'active', page_status: true },
    }, [params, page])
    const productList = products.response ? products.response.results : []
    const check = useLoad({ url: '/main/order' })
    const checkItem = check.response ? check.response.results : []
    const [showCreateModal, setShowCreateModal] = useModal(
        <ProductCreate
            reload={products}
            onCancel={() => setShowCreateModal()}
        />,
        styles.modal,
    )
    const [showCreateCheck, hideCreateCheck] = useModal(
        <CheckCreate
            reload={check}
            onCancel={() => hideCreateCheck()}
        />,
    )

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined

        // Update the URL with the search parameter
        history.push(`?${queryString.stringify({
            ...params,
            search,
        })}`)

        // Remove the search parameter immediately
        history.push(`?${queryString.stringify({
            ...params,
            search: undefined, // or delete params.search if you prefer
        })}`)
    }

    return (
        <Layout onSearch={onSearch} showCreateModal={showCreateModal} showCreateCheck={showCreateCheck}>
            <div className="columns is-multiline">
                {/* Products Section */}
                <div className="column is-12-mobile is-8-tablet">
                    {products.response && products.response.count === 0 ? (
                        <div className="is-flex is-justify-content-center is-align-items-center hei">
                            <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                        </div>
                    ) : (
                        <div className="box" style={{ width: '100%' }}>
                            <div className={css(styles.tableContainer)}>
                                <Table
                                    loading={products.loading}
                                    items={productList}
                                    columns={{
                                        photo: 'Фото',
                                        name: 'Название',
                                        count: 'Количество',
                                        price: 'Цена',
                                        actions: '',
                                    }}
                                    totalCount={products.response ? products.response.count : 0}
                                    pageSize={15}
                                    activePage={page}
                                    onPageChange={setPage}
                                    renderItem={(item) => (
                                        <ProductItem
                                            products={products}
                                            check={check}
                                            key={item.id}
                                            item={item}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    )}
                </div>

                {/* Check Section */}
                {!check.loading ? (
                    <div className="column is-12-mobile is-4-tablet">
                        {check.response && check.response.count === 0 ? (
                            <div className="is-flex is-justify-content-center is-align-items-center hei">
                                <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                            </div>
                        ) : (
                            <div>
                                {checkItem.map((item) => (
                                    <CheckItem
                                        products={products}
                                        check={check}
                                        reload={check}
                                        key={item.id}
                                        item={item}
                                    />
                                ))}
                            </div>
                        )}
                        <div className="is-flex is-justify-content-center" />
                    </div>
                ) : (
                    <Loader className={css(styles.loader)} large center />
                )}
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: {
        marginTop: '2rem',
    },
    emptyImg: {
        width: '100%', // Makes it responsive
        maxWidth: '330px',
        height: 'auto',
    },
    loader: {
        marginTop: '2rem',
    },
    modal: {
        width: '90vw', // Adjusts modal width dynamically
        maxWidth: 1000,
    },
    tableContainer: {
        overflowX: 'auto', // Enables horizontal scrolling on smaller screens
        width: '100%',
    },
})
