import React, { useState } from 'react'
import { StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Button from './common/Button'
import { CLIENT_DETAIL, SEND_MESSAGE } from '../urls'
import { useDeleteRequest, useGetRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { getDateOtherFormat, timeAgo } from '../utils/date'
import { format } from '../utils/number'
import ClientBorrowList from './ClientBorrowList'
import ClientUpdate from './ClientUpdate'
import { useMessage } from '../hooks/message'

export default function BorrowItem({
    item,
    clients,
    onCheckboxChange,
}) {
    const [isChecked, setIsChecked] = useState(false)

    const productRemove = useDeleteRequest({ url: CLIENT_DETAIL.replace('{id}', item.id) })
    const sendMessage = useGetRequest({ url: SEND_MESSAGE.replace('{clientId}', item.id) })
    const [showMessage] = useMessage()

    async function onSend() {
        const { success } = await sendMessage.request()
        if (success) {
            showMessage(`Сизнинг sms хабарингиз ${item.name} га муваффақиятли юборилди`, 'is-success')
        }
    }

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            clients.request()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(
        <ClientUpdate
            clients={clients}
            item={item}
            onCancel={() => hideUpdateModal()}
        />,
    )
    const [showClientBorrowList, hideClientBorrowList] = useModal(
        <ClientBorrowList
            clients={clients}
            client={item}
            clientId={item.id}
            hideModal={() => hideClientBorrowList()}
        />,
        styles.modal, // Apply the responsive modal styles
    )

    const currentDate = new Date()

    const handleCheckboxChange = () => {
        const newCheckedState = !isChecked
        setIsChecked(newCheckedState)
        onCheckboxChange(item.id, newCheckedState)
    }

    return (
        <tr className={`${getDateOtherFormat(currentDate) > item.term ? 'has-text-danger' : 'has-text-black'}`}
            key={item.id}>
            <td onClick={showClientBorrowList} className="">
                <span className={cn('tag is-medium is-light', `${item.sumAmount > 0 ? 'is-danger' : 'is-success'}`)}>
                    {item.name}
                </span>
            </td>
            <td className="">{item.phoneNumber}</td>
            <td className="">{format(item.sumAmount)}</td>
            <td>
                <input
                    className="checkbox"
                    style={{
                        width: 25,
                        height: 25,
                    }}
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
            </td>
            <td className="">{item.lastSendDate ? timeAgo(item.lastSendDate) : ''}</td>
            <td className="has-text-right is-flex">
                <Button
                    onClick={showUpdateModal}
                    className="mr-2 button is-success"
                    icon="pencil-outline" />
                <Button
                    onClick={onDelete}
                    className="button is-danger mr-2"
                    icon="trash" />

                <Button
                    loading={sendMessage.loading}
                    onClick={onSend}
                    className="button is-link"
                    icon="paper-plane-outline" />
            </td>
        </tr>

    )
}

const styles = StyleSheet.create({
    modal: {
        width: '90%', // Default to 90% of the screen width
        maxWidth: 800, // Ensures it doesn't exceed 800px
        '@media (max-width: 768px)': {
            width: '95%', // Adjust width for tablets
        },
        '@media (max-width: 480px)': {
            width: '100%', // Full width for mobile screens
        },
    },
})
